.tablemodelContainer{
    width: 100%;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.lockedHeader{
    
}
.hoverValueRow:hover{
    background-color: var(--container-shadow-color-hover-more-lighter);
}
.sortingIcon:hover{
    color: rgb(100,100,100);
}