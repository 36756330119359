.insightAdvertiserContainer{
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: calc(100% - 40px);
    margin: 20px;

}

.insightAdvertiserWrapper{
    padding-top: 10px;
    width: 100%;
}

.slider{
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-around;
    font-size: 12px;
    width: 60px;
    position: relative;
    background-color: white;
    border-radius: 5px;
}
.sliderHover{
    position: absolute;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    transition: all 0.2s linear;
}

.monthPicker{
    cursor: pointer;
    outline: none;
    border: 1px solid transparent;
    padding: 10px;
    min-width: 220px;   
    width: auto;
    /* max-width: 200px;    */
    font-family:Arial, Helvetica, sans-serif;
    color: black;
    font-weight: 500;
    /* border-bottom: 1px solid rgb(200,200,200); */
}
.insightAdvertiserFilterRow{
    height: 50px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(200,200,200);
}

.filterAddContainer{
    height: 300px;
    width: 200px;
    position: absolute;
    border: 1px solid rgb(200,200,200);
    background-color: white;
}

.topAnatomy{
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;

}
.topItemWrapper{
    width: 50%;
    min-height: 200px;
    margin: 30px;
    margin-top: 10px;
    height: 300px;
}
.topItemHeading{
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selectPending{
    outline: none  !important;
    border: 0px solid black  !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19px;
    font-weight: 500;
    margin-left: 2px;
}