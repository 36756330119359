
.statsV2FilterContainer{
    display: flex;
    flex-wrap: wrap;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 10px;
    background-color: white;
}

.statsV2FilterLabel{
    font-size: 11px;
    font-weight: 500;
    color: rgb(55, 55, 55);
}

.statsV2ButtonContainer{
    display: flex;
    align-items: center;
    margin-left: 1%;
    margin-right: 1%;
    padding-top: 15px;
    background-color: white;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(200,200,200);
    margin-bottom: 20px;
}

.statsV2TableHeader{
    display: flex;
    position: sticky;
    background-color: white;
    padding-left: 2%;
    padding-right: 2%;
    z-index: 1;
}

.statsV2TableValue{
    display: flex;
    background-color: white;
    padding-left: 2%;
    padding-right: 2%;
}

.statsV2TableHeaderData{
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    margin: 1px;
    /* background-color: rgb(240, 240, 240); */
    cursor: pointer;
}
.statsV2TableValueData{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 1px;
}

.overflowText{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.statsV2TableTotalValueStyles{
    display: flex;
    position: sticky;
    bottom:20px;
    background-color: white;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 30px;
    z-index: 1;
}

.statsV2TableTotalValueData{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    margin: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(170, 170, 170);
}
.selectedConversionsV2{
    height: 50px;
    display:flex;   
    position: sticky;
    top:0;
    margin-left: 2%;
    margin-right: 2%;
    padding-left: 2%;
    padding-right: 2%;
    z-index: 2;
}

.statsV2AnchorLink{
    text-decoration: none;
    color: black;
}

.statsV2AnchorLink:hover{
    text-decoration: underline;
}

.rdrDayNumber{
    font-weight: 500;
}
.rdrDateRangePickerWrapper{
    border: 1px solid var(--container-shadow-color-hover);
}
@media screen and (max-width: 800px) {
    .rdrDefinedRangesWrapper {
        display: none;
    }
}