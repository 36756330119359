.mainWindowSidebarOpen{
    padding-left: 220px;    
    max-width : calc(100vw - 220px);
}

.mainWindowSidebarClose{
    padding-left: 65px;    
    max-width : calc(100vw - 65px);
}

@media screen and (max-width: 600px) {
    .mainWindowSidebarClose{
        padding-left: 0px;    
        max-width : 100vw;
    }
    .mainWindowSidebarOpen{
        padding-left: 0px;    
        max-width : 100vw;
    }
}