.insightsDB{
    padding: 25px;
    padding-top: 5px;
    font-family: 'Kanit';
}
.v3PageHeading{
    font-family: "Oswald";
    font-size: 34px;
}
.v3FiltersRow{
    min-height: 50px;
    margin-top: 20px;
    display: flex;
    width: calc(100% - 40px);
    flex-wrap:wrap;
    justify-content: space-between;
}

.v3FilterRowItem{
    width: calc(20% - 2px);
    background: #E9E9E9;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.v3FilterRowItem:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.v3FilterRowItem:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}